import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useFetch } from 'use-http';
import { toast } from 'react-toastify';

import tailwindConfig from '../../../tailwind.config';
import getLocalLink from '../../../utils/getLocalLink';
import handleResponse from '../../../utils/handleResponse';

import { NameBody } from './Columns/Name';
import { TurnoverBody } from './Columns/Turnover';
import { SectorBody } from './Columns/Sector';
import { TagsBody } from './Columns/Tags';
import { CategoryBody } from './Columns/Category';
import { ClimatActionBody } from './Columns/ClimatAction';
import { RythmeReduction12Body } from './Columns/RythmeReduction12';
import { RythmeReduction3Body } from './Columns/RythmeReduction3';
import { SbtTargetBody } from './Columns/SbtTarget';
import { StatusBody } from './Columns/Status';
import { ObjectifRythmeReduction12Body } from './Columns/ObjectifRythmeReduction12';
import { ObjectifRythmeReduction3Body } from './Columns/ObjectifRythmeReduction3';
import { CdpBody } from './Columns/Cdp';
import { ActionsBody } from './Columns/Actions';
import { RatingBody } from './Columns/Rating';
import { CarbonWeightBody } from './Columns/CarbonWeight';
import { PartnerTurnoverBody } from './Columns/PartnerTurnover';
import { GroupBody } from './Columns/Group';
import { ActionsCompletedBody } from './Columns/ActionsCompleted';
import { SbtTargetSimpleBody } from './Columns/TargetSbtSimple';
import { CurrentProgressBody } from './Columns/CurrentProgress';
import ToastMessage from '../../Dashboard/Category/ToastMessage';
import DeleteTagConfirmationModal from './DeleteTagConfirmationModal';
import Drawer from './Drawer';
import CreateTagForm from './CreateTagForm';

import chevronDown from '../../../assets/ui/chevron-down-grey.svg';
import closeIcon from '../../../assets/ui/cross-modal.svg';
import threeDots from '../../../assets/ui/three-dots.svg';

function TableRow({ partner, partnerTags, getPartnerTags, category, profileStatusList, profile, tableComponent, hasWriteAccess, edit, isDashboard, cellStyle, categoryFilter, getProfiles, sectorFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, sort, filter }) {
  const [currentProfile, setCurrentProfile] = useState(profile);
  const [profileTags, setProfileTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [profileEditStatus, setProfileEditStatus] = useState(false);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [isTagDropdownOpen, setIsTagDropdownOpen] = useState(false);
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [shouldHardRefreshProfiles, setShouldHardRefreshProfiles] = useState(false);
  const [tonnageGlobal, setTonnageGlobal] = useState(0);
  const [hoveredTagId, setHoveredTagId] = useState(null);
  const [isEditingTag, setIsEditingTag] = useState(false);
  const [tagToEdit, setTagToEdit] = useState(null);
  const [openMenuTagId, setOpenMenuTagId] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [tagToDelete, setTagToDelete] = useState(null);

  const tagDropdownRef = useRef(null);
  const tagTriggerRef = useRef(null);

  const { session } = useOutletContext();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // CONFIGURATION DU FORMULAIRE
  const defaultValues = {
    rating: currentProfile?.rating,
    carbon_weight: parseInt(currentProfile?.partner_carbon_weight, 10) / 1000,
    turnover: currentProfile?.partner_turnover,
    status: currentProfile?.status_id,
    tags: [],
    ...currentProfile?.categories?.reduce((acc, category) => {
      acc[`carbon_weight_${category.id}`] = category.carbon_weight / 1000 || '';
      return acc;
    }, {}),
  };

  const { control, register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
    defaultValues,
  });

  // APPELS API
  const { put, post, del, loading, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const categoriesCarbonWeight = currentProfile?.categories?.map((category) => ({
    [`carbon_weight_${category.id}`]: category.value,
  }));

  // USEEFFECT
  useEffect(() => {
    const profileObj = edit?.find((obj) => obj.id === currentProfile.id);
    if (profileObj) {
      setProfileEditStatus(profileObj.edit);
    }
  }, [edit]);

  useEffect(() => {
    watch((value) => {
      const totalCarbonWeight = Object.keys(value)
        .filter((key) => key.startsWith('carbon_weight_'))
        .reduce((sum, key) => sum + (parseFloat(value[key]) || 0), 0);

      setTonnageGlobal(totalCarbonWeight);
    });
  }, [watch]);

  useEffect(() => {
    if (partnerTags && partnerTags.length > 0) {
      setAvailableTags(partnerTags);
    }
  }, [partnerTags]);

  useEffect(() => {
    if (profile && profile.tags) {
      setProfileTags(profile.tags);
    }
  }, [profile]);

  useEffect(() => {
    if (profileTags.length > 0) {
      setSelectedTags(profileTags);
      setAvailableTags(partnerTags && partnerTags.filter((tag) => !profileTags.some((profileTag) => profileTag.id === tag.id)));
      setValue('tags', profileTags.map((tag) => tag.id));
    }
  }, [profileTags, partnerTags]);

  useEffect(() => {
    if (drawerVisibility) {
      setSelectedTags(profileTags);
      setAvailableTags(partnerTags && partnerTags.filter((tag) => !profileTags.some((profileTag) => profileTag.id === tag.id)));
      setValue('tags', profileTags.map((tag) => tag.id));
    }
  }, [drawerVisibility, profileTags, partnerTags]);

  // GESTION DE FORMULAIRE
  const onSubmit = async (data) => {
    let ratingResponse;

    if (defaultValues.rating !== data.rating) {
      const body = {
        rating: data.rating,
        partner_id: partner.id,
        currentProfile_id: currentProfile.id,
      };

      if (currentProfile.rating_id !== null && currentProfile.rating_id !== '' && currentProfile.rating_id !== undefined) {
        ratingResponse = await put(`/partner/${partner.id}/profiles/${currentProfile.id}/rating/${currentProfile.rating_id}`, body);
      } else {
        ratingResponse = await post(`/partner/${partner.id}/profiles/${currentProfile.id}/rating`, body);
      }

      handleResponse(response);
    }

    if (defaultValues.carbon_weight !== data.carbon_weight) {
      const body = {
        carbon_weight: data.carbon_weight,
        partner_id: partner.id,
        currentProfile_id: currentProfile.id,
      };

      if (currentProfile.partner_carbon_weight_id !== null) {
        await put(`/partner/${partner.id}/profiles/${currentProfile.id}/carbon-weight/${currentProfile.partner_carbon_weight_id}`, body);
      } else {
        await post(`/partner/${partner.id}/profiles/${currentProfile.id}/carbon-weight`, body);
      }

      handleResponse(response);
    }

    if (defaultValues.turnover !== data.turnover) {
      const body = {
        turnover: data.turnover,
        partner_id: partner.id,
        currentProfile_id: currentProfile.id,
      };

      if (currentProfile.partner_turnover_id !== null) {
        await put(`/partner/${partner.id}/profiles/${currentProfile.id}/turnover/${currentProfile?.partner_turnover_id}`, body);
      } else {
        await post(`/partner/${partner.id}/profiles/${currentProfile.id}/turnover/`, body);
      }

      handleResponse(response);
    }

    if (defaultValues.status !== data.status) {
      const body = {
        status_id: data.status,
        partner_id: partner.id,
        currentProfile_id: currentProfile.id,
      };

      if (currentProfile.current_status_id !== null) {
        await put(`/partner/${partner.id}/profiles/${currentProfile.id}/status/${currentProfile.current_status_id}`, body);
      } else {
        await post(`/partner/${partner.id}/profiles/${currentProfile.id}/status`, body);
      }

      handleResponse(response);
    }

    const status = profileStatusList.filter((profile) => profile.id === data.status);

    await Promise.all(categoriesCarbonWeight.map(async (category) => {
      const defaultValue = defaultValues[Object.keys(category)[0]];
      const dataValue = data[Object.keys(category)[0]];

      if (dataValue !== defaultValue) {
        const body = {
          user_id: session.id,
          carbon_weight: dataValue,
        };

        try {
          await post(`/partner/${partner.id}/profiles/${currentProfile.id}/carbon-weight-category/${Object.keys(category)[0].split('_')[2]}`, body);
        } catch (error) {
          handleResponse(response);
          throw new Error(error);
        }
      }
    }));

    setCurrentProfile({ ...currentProfile,
      status_id: data.status,
      status_name: status[0]?.name,
      status_color: status[0]?.color,
      partner_turnover: data.turnover,
      partner_carbon_weight: (parseInt(data.carbon_weight, 10) * 1000),
      rating: data.rating,
      rating_id: ratingResponse,
      categories: currentProfile.categories.map((category) => ({
        ...category,
        carbon_weight: data[`carbon_weight_${category.id}`] * 1000,
      })),
    });

    toast(<ToastMessage text={<>{t('SUPPLIER_EDITED')} <span className=" tw-font-moskauGrotesk tw-font-semibold">{data.name}</span></>} />, {
      style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
    });

    setDrawerVisibility(false);
    setIsTagDropdownOpen(false);

    if (shouldHardRefreshProfiles) {
      await getProfiles(sort, filter, sectorFilter, categoryFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, 0);
    }

    setShouldHardRefreshProfiles(false);
  };

  // FONCTIONS DE GESTION DES TAGS
  const handleTagAddition = async (tag) => {
    try {
      const body = {
        partner_id: partner.id,
        partner_tags_id: [tag.id],
      };

      await post(`/profiles/${currentProfile.id}/tags`, body);

      if (response.ok) {
        setSelectedTags((prevTags) => [...prevTags, tag]);
        setAvailableTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
        setValue('tags', [...selectedTags, tag].map((t) => t.id));
        setProfileTags((prevTags) => [...prevTags, tag]);
        setShouldHardRefreshProfiles(true);
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  const handleTagSelection = (tag) => {
    handleTagAddition(tag);
  };

  const handleTagRemoval = async (tag) => {
    try {
      const partnerTagsIds = JSON.stringify([tag.id]);
      await del(`/profiles/${currentProfile.id}/tags?partner_id=${partner.id}&partner_tags_id=${encodeURIComponent(partnerTagsIds)}`);

      if (response.ok) {
        setSelectedTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
        setAvailableTags((prevTags) => [...prevTags, tag]);
        setValue('tags', selectedTags.filter((t) => t.id !== tag.id).map((t) => t.id));
        setProfileTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
        setShouldHardRefreshProfiles(true);
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  const toggleTagDropdown = () => setIsTagDropdownOpen(!isTagDropdownOpen);

  const handleTagKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleTagDropdown();
    }
  };

  const handleCreateTag = () => {
    setIsCreatingTag(true);
  };

  const handleCancelCreateTag = () => {
    setIsCreatingTag(false);
  };

  const handleTagCreated = async (createdTag) => {
    setSelectedTags((prevTags) => {
      if (prevTags.some((tag) => tag.id === createdTag.id)) {
        return prevTags;
      }
      const newTags = [...prevTags, createdTag];
      return newTags;
    });

    setAvailableTags((prevTags) => prevTags.filter((tag) => tag.id !== createdTag.id));

    setValue('tags', (oldValue) => {
      const currentTags = Array.isArray(oldValue) ? oldValue : [];
      return [...new Set([...currentTags, createdTag.id])];
    });

    setIsCreatingTag(false);

    await handleTagAddition(createdTag);
    await getPartnerTags();
  };

  const handleEditTag = (tag) => {
    setTagToEdit(tag);
    setIsEditingTag(true);
    setIsTagDropdownOpen(false);
    setOpenMenuTagId(null);
  };

  const handleCancelEditTag = () => {
    setIsEditingTag(false);
    setTagToEdit(null);
  };

  const handleTagEdited = (editedTag) => {
    setAvailableTags(availableTags.map((tag) => (tag.id === editedTag.id ? editedTag : tag)));
    setIsEditingTag(false);
    setTagToEdit(null);
  };

  const handleDeleteTag = (tag) => {
    setTagToDelete(tag);
    setIsDeleteConfirmationOpen(true);
    setOpenMenuTagId(null);
  };

  const confirmDeleteTag = async () => {
    try {
      await del(`/partner/${partner.id}/tags/${tagToDelete.id}`);

      if (response.ok) {
        getPartnerTags();
        setAvailableTags(availableTags.filter((tag) => tag.id !== tagToDelete.id));
        setSelectedTags(selectedTags.filter((tag) => tag.id !== tagToDelete.id));
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    } finally {
      setIsDeleteConfirmationOpen(false);
      setTagToDelete(null);
    }
  };

  const handleToggleMenu = (tagId) => {
    setOpenMenuTagId(openMenuTagId === tagId ? null : tagId);
  };

  const handleClickOutside = (event) => {
    if (tagDropdownRef.current && !tagDropdownRef.current.contains(event.target)) {
      setOpenMenuTagId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // NAVIGATION
  const onClick = (currentProfile) => {
    if (profileEditStatus === false) {
      const basePath = isDashboard ? '/dashboard/suppliers' : '/profile';
      const newLink = getLocalLink(`${basePath}/${currentProfile.slug}`);
      const searchParam = category ? `${searchParams.get('partner') ? '&' : '?'}cf=${category}` : '';
      navigate(`${newLink}${searchParam}`);
      window.scrollTo(0, 0);
    }
  };

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    setIsTagDropdownOpen(false);
    setIsCreatingTag(false);
  };

  // FONCTIONS DE RENDU
  const renderTagOptions = (tag) => (
    <div className="tw-relative">
      <button
        type="button"
        onClick={() => handleToggleMenu(tag.id)}
        className="tw-w-[26px] tw-h-[26px] tw-rounded-full tw-bg-greyEight tw-border-none tw-flex tw-items-center tw-justify-center tw-ml-3 tw-cursor-pointer"
      >
        <img src={threeDots} alt="edit tag" />
      </button>
      {openMenuTagId === tag.id && (
        <>
          <div className="tw-absolute tw-left-12 tw-top-0 tw-flex tw-flex-col tw-p-[3px] tw-rounded tw-shadow-tooltipShadow tw-bg-white">
            <button
              type="button"
              className="tw-text-greyThree tw-text-sm tw-font-medium tw-font-moskauGrotesk tw-bg-white hover:tw-bg-greyEight tw-border-none tw-text-left tw-px-[7px] tw-py-[2px] tw-rounded-sm tw-transition-transform tw-duration-150 tw-z-10"
              onClick={() => handleEditTag(tag)}
            >
              {t('EDIT_TAG_B')}
            </button>
            <button
              type="button"
              className="tw-text-greyThree tw-text-sm tw-font-medium tw-font-moskauGrotesk tw-mt-2 tw-bg-white hover:tw-bg-greyEight tw-border-none tw-text-left tw-px-[7px] tw-py-[2px] tw-rounded-sm tw-transition-transform tw-duration-150"
              onClick={() => handleDeleteTag(tag)}
            >
              {t('DELETE_TAG')}
            </button>
          </div>
          <div className="tw-absolute tw-left-[42px] tw-top-2 tw-w-3 tw-h-3 tw-rotate-45 tw-bg-white tw-shadow-tooltipShadow" />
        </>

      )}
    </div>
  );

  const renderTagDropdown = () => (
    <div className="tw-z-10 tw-w-full tw-bg-white tw-border tw-border-t-0 tw-border-solid tw-border-greySix tw-rounded-b-md tw-shadow-companyCard tw-max-h-60 tw-overflow-auto">
      {availableTags.length === 0 ? (
        <div className="tw-text-sm tw-text-greyThree tw-font-moskauGrotesk tw-p-[14px] tw-border-b tw-border-greyNine">
          {t('NO_TAG_AVAILABLE')}
        </div>
      ) : (
        <div className="tw-flex tw-flex-col tw-gap-3 tw-py-[14px] tw-px-6">
          {availableTags.map((tag) => (
            <div
              key={`available-${tag.id}`}
              className="tw-flex tw-items-center"
              onMouseEnter={() => setHoveredTagId(tag.id)}
              onMouseLeave={() => setHoveredTagId(null)}
            >
              <button
                type="button"
                onClick={() => handleTagSelection(tag)}
                className="tw-py-[6px] tw-px-3 tw-rounded tw-text-sm tw-font-moskauGrotesk tw-font-medium tw-text-greyOne tw-border-none tw-cursor-pointer"
                style={{ backgroundColor: tag.color || tailwindConfig.theme.colors.tagsGrey }}
              >
                {tag.name}
              </button>
              {hoveredTagId === tag.id && renderTagOptions(tag)}
            </div>
          ))}
        </div>
      )}
      <button
        type="button"
        onClick={handleCreateTag}
        className="tw-w-full tw-bg-greyEight tw-text-sm tw-text-primaryNewBlue tw-border tw-border-t tw-border-x-0 tw-border-b-0 tw-border-solid tw-border-greySix tw-p-[14px] tw-text-start"
      >
        {t('CREATE_NEW_TAG')}
      </button>
    </div>
  );

  const renderTagSection = () => {
    if (isCreatingTag) {
      return (
        <CreateTagForm
          partner={partner}
          onCancel={handleCancelCreateTag}
          onCreate={handleTagCreated}
          partnerTags={partnerTags}
        />
      );
    }

    if (isEditingTag) {
      return (
        <CreateTagForm
          partner={partner}
          onCancel={handleCancelEditTag}
          onCreate={handleTagEdited}
          partnerTags={partnerTags}
          getPartnerTags={getPartnerTags}
          tagToEdit={tagToEdit}
        />
      );
    }

    return (
      <div className="tw-w-full tw-flex tw-flex-col tw-border tw-border-solid tw-border-greySix tw-rounded-[4px] tw-p-4 tw-mt-5">
        <span className="tw-text-xl tw-font-medium tw-text-greyTwo tw-font-moskauGrotesk">{t('TAGS')}</span>
        <span className="tw-text-greyTwo tw-font-moskauGrotesk tw-mt-5">{t('TAGS_DESCRIPTION')}</span>

        <Controller
          name="tags"
          control={control}
          render={() => (
            <div className="tw-flex tw-flex-col tw-mt-[30px]" ref={tagDropdownRef}>
              <label htmlFor="tags" className="tw-block tw-text-base tw-font-moskauGrotesk tw-font-medium tw-text-greyOne tw-mb-2.5">
                {t('TAGS')}
              </label>
              <div
                ref={tagTriggerRef}
                tabIndex="0"
                className={`tw-min-h-[46px] tw-rounded-t-md ${isTagDropdownOpen ? 'tw-rounded-b-none' : 'tw-rounded-b'} tw-rounded-b-none tw-p-2 tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-border tw-border-solid tw-border-greySix focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none`}
                onClick={toggleTagDropdown}
                onKeyDown={handleTagKeyDown}
                role="button"
                aria-haspopup="listbox"
                aria-expanded={isTagDropdownOpen}
              >
                <div className="tw-flex tw-flex-wrap tw-gap-2">
                  {selectedTags.length > 0 ? (
                    selectedTags.map((tag, index) => (
                      <div key={`selected-${tag.id}-${index}`} className="tw-flex tw-items-center tw-rounded tw-py-[6px] tw-px-3" style={{ backgroundColor: tag.color ? tag.color : tailwindConfig.theme.colors.tagsGrey }}>
                        <span className="tw-text-sm tw-font-moskauGrotesk tw-font-medium tw-text-greyOne -tw-mb-[4px]">{tag.name}</span>
                        <button
                          type="button"
                          className="tw-border-none tw-p-0"
                          style={{ backgroundColor: tag.color ? tag.color : tailwindConfig.theme.colors.tagsGrey }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTagRemoval(tag);
                          }}
                        >
                          <img
                            src={closeIcon}
                            alt="remove"
                            className="tw-w-[18px] tw-h-[18px] tw-ml-1 tw-cursor-pointer"
                          />
                        </button>
                      </div>
                    ))
                  ) : (
                    <span className="tw-text-greyFour tw-font-moskauGrotesk">{t('TAGS_SELECT_OR_CREATE')}</span>
                  )}
                </div>
                <img
                  src={chevronDown}
                  alt="chevron"
                  className={`tw-transition-transform tw-duration-300 ${isTagDropdownOpen ? 'tw-transform tw-rotate-180' : ''}`}
                />
              </div>
              {isTagDropdownOpen && renderTagDropdown()}
            </div>
          )}
        />
      </div>
    );
  };

  // RENDU PRINCIPAL
  return (
    <>
      <tr className={`tw-border-solid tw-border-greySeven tw-border ${profileEditStatus ? 'table-row-no-hover' : 'table-row'} align-middle`} onClick={() => onClick(currentProfile)}>
        <NameBody profile={currentProfile} cellStyle={cellStyle} />
        {tableComponent?.profileGroup && <GroupBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.turnover && <TurnoverBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.sector && <SectorBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.category && <CategoryBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.profileTurnover && <PartnerTurnoverBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.profileCarbonWeight && <CarbonWeightBody profile={currentProfile} cellStyle={cellStyle} categoryFilter={categoryFilter} partnerId={partner.id} loading={loading} />}
        {tableComponent?.climateAction && <ClimatActionBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.scope12 && <RythmeReduction12Body profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.scope3 && <RythmeReduction3Body profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.objScope12 && <ObjectifRythmeReduction12Body profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.objScope3 && <ObjectifRythmeReduction3Body profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.targetSbt && <SbtTargetBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.targetSbtSimple && <SbtTargetSimpleBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.currentProgress && <CurrentProgressBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.scoreCdp && <CdpBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.tags && <TagsBody profile={profile} cellStyle={cellStyle} />}
        {tableComponent?.actionsCompleted && <ActionsCompletedBody profile={currentProfile} cellStyle={cellStyle} />}
        {tableComponent?.profileRating && <RatingBody profile={currentProfile} partner={partner} cellStyle={cellStyle} />}
        {tableComponent?.status && <StatusBody profile={currentProfile} cellStyle={cellStyle} />}
        {(hasWriteAccess && (tableComponent?.status || tableComponent?.profileRating)) && <ActionsBody cellStyle={cellStyle} setDrawerVisibility={setDrawerVisibility} />}
      </tr>
      <Drawer
        title={t('DRAWER_SUPPLIER_TITLE')}
        drawerVisibility={drawerVisibility}
        setDrawerVisibility={handleCloseDrawer}
        setIsTagDropdownOpen={setIsTagDropdownOpen}
        className={drawerVisibility ? '' : 'tw-hidden'}
        profile={currentProfile}
        partner={partner}
        tableComponent={tableComponent}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      >
        <div className="tw-bg-backgroundCleanBlue border-greySix border tw-py-3 tw-px-7 tw-rounded-sm d-flex align-items-center">
          <img src={`${process.env.REACT_APP_FILES_DOMAIN}${currentProfile.logo_url}`} alt={currentProfile.name} className="border-greySix border tw-rounded-full tw-w-12 tw-h-12 tw-mr-5" />
          {currentProfile.name}
        </div>
        {tableComponent?.profileRating && (
          <div className="tw-mt-8">
            <label htmlFor="rating" className="tw-block tw-text-base tw-font-medium tw-text-greyOne tw-mb-2.5">
              Note {partner.name}
            </label>
            <input
              id="rating"
              type="number"
              step=".01"
              className={`border tw-rounded-md tw-text-greyFour tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full ${errors.rating ? 'border-redError focus:tw-outline-redError' : 'border-greySix focus:tw-outline-secondarySkyBlue'}`}
              {...register('rating', {
                max: {
                  value: partner.max_rating,
                  message: `${t('RATING_ERROR_2')} (${partner.max_rating})`,
                },
                min: {
                  value: 0,
                  message: t('RATING_ERROR_1'),
                },
              })}
            />
            {errors && errors.rating && (
              <span className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors?.rating?.message}</span>
            )}
          </div>
        )}
        {tableComponent?.profileTurnover && (
          <div className="tw-mt-8">
            <label htmlFor="turnover" className="tw-block tw-text-base tw-font-medium tw-text-greyOne tw-mb-2.5">
              {t('TURNOVER_WITH')} {partner.name}
            </label>
            <div className="tw-flex">
              <input
                id="turnover"
                type="number"
                step=".01"
                className={`border tw-rounded-md tw-text-greyFour tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full ${errors.turnover ? 'border-redError focus:tw-outline-redError' : 'border-greySix focus:tw-outline-secondarySkyBlue'}`}
                {...register('turnover', {
                  min: {
                    value: 0,
                    message: t('TURNOVER_ERROR_1'),
                  },
                })}
              />
              <span className="tw-ml-2 tw-self-center">
                mn€
              </span>
            </div>
            {errors && errors.turnover && (
              <span className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors?.turnover?.message}</span>
            )}
          </div>
        )}
        {tableComponent?.status && (
          <div className="tw-mt-8">
            <label htmlFor="status" className="tw-block tw-text-base tw-font-medium tw-text-greyOne tw-mb-2.5">
              {t('STATUS_SUPPLIER')}
            </label>
            <select
              className={`border tw-rounded-md tw-text-greyFour tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full ${errors.status ? 'border-redError focus:tw-outline-redError' : 'border-greySix focus:tw-outline-secondarySkyBlue'}`}
              {...register('status')}
            >
              <option value="default" disabled>{t('CHOOSE_STATUS')}</option>
              {profileStatusList?.sort((a, b) => a.position - b.position).map((status, index) => (
                <option key={index} value={status.id}>{t(status.name)}</option>
              ), [])}
            </select>
          </div>
        )}
        {tableComponent?.profileCarbonWeight && (
          <>
            <div className="tw-mt-8 tw-p-4 tw-border tw-border-solid tw-border-greySix tw-rounded-t tw-font-moskauGrotesk">
              <div className="tw-text-[20px] tw-font-medium tw-text-greyTwo tw-mb-5">
                {t('CARBON_WEIGHT')}
              </div>
              <div className="tw-text-greyTwo tw-text-base tw-mb-10">
                {t('CARBON_WEIGHT_DESCRIPTION')}
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-gap-2.5 tw-text-greyOne tw-max-h-96 tw-overflow-y-auto">
                <div className="tw-col-span-1 tw-font-medium tw-mb-2.5 tw-text-greyOne">
                  {t('CATEGORY')}
                </div>
                <div className="tw-col-span-1 tw-font-medium tw-mb-2.5 tw-text-greyOne">
                  {t('TONNAGE')}
                </div>
                {currentProfile && currentProfile.categories?.map((category, index) => (
                  <React.Fragment key={category.id + category.carbon_weight + index}>
                    <div className="tw-col-span-1 tw-items-center tw-flex">
                      {category.name}
                    </div>
                    <div className={`tw-flex tw-col-span-1 tw-rounded-md tw-mb-2.5 tw-mr-[1px] ${errors.carbon_weight ? 'parent-outline-red-error' : 'parent-outline'}`}>
                      <input
                        id="carbon_weight"
                        type="number"
                        step=".001"
                        min={0}
                        className={`custom-number-input tw-border-l tw-border-r-0 tw-border-t tw-border-b tw-border-solid tw-rounded-l-md tw-text-greyFour tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full ${errors.carbon_weight ? 'tw-border-redError focus:tw-outline-none focus:tw-border-l focus:tw-border-t focus:tw-border-b focus:tw-border-redError' : 'tw-border-greySix focus:tw-outline-none focus:tw-border-l focus:tw-border-t focus:tw-border-b focus:tw-border-secondarySkyBlue'}`}
                        {...register(`carbon_weight_${category.id}`)}
                      />
                      <span className={`tw-pr-4 tw-flex tw-items-center tw-h-full tw-self-center tw-border-solid tw-border-l-0 tw-border-r tw-border-t tw-border-b ${errors.carbon_weight ? 'tw-border-redError' : 'tw-border-greySix'} tw-rounded-r-md`}>
                        kt
                      </span>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="tw-flex tw-justify-between tw-bg-greyEight tw-border-solid tw-border-greySix tw-border-t-0 tw-border-x-[1px] tw-border-b-[1px] tw-px-4 tw-py-2.5 tw-rounded-b">
              <div className="tw-text-greyFour tw-font-medium">
                {t('TONNAGE_GLOBALE')}
              </div>
              <div className="tw-text-secondarySkyBlue tw-font-semibold">
                {tonnageGlobal} kt
              </div>
            </div>
          </>
        )}
        {/* BLOC ETIQUETTE */}
        {tableComponent?.tags && renderTagSection()}
        <DeleteTagConfirmationModal
          isOpen={isDeleteConfirmationOpen}
          onClose={() => setIsDeleteConfirmationOpen(false)}
          onConfirm={confirmDeleteTag}
          title={t('DELETE_TAG_CONFIRMATION_TITLE')}
          message={t('DELETE_TAG_CONFIRMATION_MESSAGE', { tagName: tagToDelete?.name })}
        />
      </Drawer>
    </>

  );
}

export default TableRow;
