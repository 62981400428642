import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import crossModal from '../../../assets/ui/cross-modal.svg';

function DeleteTagConfirmationModal({ isOpen, onClose, onConfirm, title, message = null }) {
  const { t } = useTranslation();
  const modalRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-greyOne/50 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div ref={modalRef} className="tw-max-w-[375px] tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-7">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <h2 className="tw-text-base tw-text-greyOne tw-font-medium tw-m-0">{title}</h2>
          <button type="button" className="tw-flex tw-flex-col tw-justify-start tw-border-none tw-bg-white" onClick={onClose}>
            <img src={crossModal} alt="close icon" />
          </button>
        </div>
        {message && <p className="tw-text-sm tw-text-greyThree tw-font-moskauGrotesk tw-mt-4">{message}</p>}
        <div className="tw-flex tw-items-center tw-gap-x-3 tw-mt-9">
          <button
            type="button"
            className="tw-py-[13px] tw-px-7 tw-bg-primaryCorail hover:tw-bg-primaryCorailHover tw-text-sm tw-text-white tw-font-moskauGrotesk tw-font-medium tw-border-none tw-rounded-md"
            onClick={onConfirm}
          >
            {t('DELETE_TAG')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteTagConfirmationModal;
