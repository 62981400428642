import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFetch } from 'use-http';
import downArrowGrey from '../../../assets/ui/table-down-arrow-grey.svg';

import { NameHead } from './Columns/Name';
import { TurnoverHead } from './Columns/Turnover';
import { SectorHead } from './Columns/Sector';
import { TagsHead } from './Columns/Tags';
import { CategoryHead } from './Columns/Category';
import { ClimatActionHead } from './Columns/ClimatAction';
import { RythmeReduction12Head } from './Columns/RythmeReduction12';
import { RythmeReduction3Head } from './Columns/RythmeReduction3';
import { SbtTargetHead } from './Columns/SbtTarget';
import { StatusHead } from './Columns/Status';
import { ObjectifRythmeReduction12Head } from './Columns/ObjectifRythmeReduction12';
import { ObjectifRythmeReduction3Head } from './Columns/ObjectifRythmeReduction3';
import { CdpHead } from './Columns/Cdp';
import { ActionsHead } from './Columns/Actions';
import { RatingHead } from './Columns/Rating';
import { ActionsCompletedHead } from './Columns/ActionsCompleted';
import { CarbonWeightHead } from './Columns/CarbonWeight';
import { PartnerTurnoverHead } from './Columns/PartnerTurnover';
import { GroupHead } from './Columns/Group';
import { SbtTargetSimpleHead } from './Columns/TargetSbtSimple';
import { CurrentProgressHead } from './Columns/CurrentProgress';
import TableRow from './TableRow';

function Table({ profiles, partner, profileStatusList, tableComponent, filter, setFilter, sort, setSort, categoryFilter, hasWriteAccess, edit, isDashboard, loading, total, togglePage, features, getProfiles, sectorFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter }) {
  const [cellStyle, setCellStyle] = useState({ width: '200px', minWidth: '200px', maxWidth: '200px' });
  const [partnerTags, setPartnerTags] = useState([]);

  const { t } = useTranslation();

  const tableRef = useRef(null);

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/tags`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const getPartnerTags = async () => {
    try {
      await get();
      if (response.ok) {
        setPartnerTags(response.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    getPartnerTags();
  }, [partner]);

  const getCellStyle = (length, containerWidth) => {
    switch (length) {
      case 1:
        return { width: (containerWidth / 2) - 2, minWidth: (containerWidth / 2) - 2, maxWidth: (containerWidth / 2) - 2 };

      case 2:
        return { width: (containerWidth / 3) - 2, minWidth: (containerWidth / 3) - 2, maxWidth: (containerWidth / 3) - 2 };

      case 3:
        return { width: (containerWidth / 4) - 2, minWidth: (containerWidth / 4) - 2, maxWidth: (containerWidth / 4) - 2 };

      case 4:
        return { width: (containerWidth / 5) - 2, minWidth: (containerWidth / 5) - 2, maxWidth: (containerWidth / 5) - 2 };

      case 5:
        return { width: (containerWidth / 6) - 2, minWidth: (containerWidth / 6) - 2, maxWidth: (containerWidth / 6) - 2 };

      default:
        return { width: '200px', minWidth: '200px', maxWidth: '200px' };
    }
  };

  useEffect(() => {
    if (features) {
      setCellStyle(getCellStyle(features.length, tableRef.current.offsetWidth));
    }
  }, [features]);

  const trueCount = tableComponent ? Object.keys(tableComponent).filter((key) => tableComponent[key] === true).length : 0;

  const adjustedTrueCount = hasWriteAccess ? trueCount + 2 : trueCount + 1;

  const skeletonTdElements = Array.from({ length: adjustedTrueCount }, (_, index) => (
    <td key={index} className="tw-px-3 tw-border-solid tw-border-greySeven tw-border tw-text-center" style={cellStyle}>
      <div className="tw-bg-greySeven tw-animate-pulse tw-h-8 rounded m-3" />
    </td>
  ));

  const skeletonTrElements = Array.from({ length: 10 }, (_, index) => (
    <tr key={index} className="tw-border-solid tw-border-greySeven tw-border align-middle table-row-no-hover">
      {skeletonTdElements}
    </tr>
  ));

  return (
    <div ref={tableRef} id="table-container" className="d-lg-block pt-4 mb-5 false tw-overflow-x-auto">
      <table className="dashboard-table">
        <thead className="align-middle table-header tw-text-greyDarkHeavy">
          <tr>
            <NameHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />
            {tableComponent?.profileGroup && <GroupHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.turnover && <TurnoverHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.sector && <SectorHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.category && <CategoryHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} categoryLevel0={partner.category_level?.find((element) => element.position === 0)} cellStyle={cellStyle} />}
            {tableComponent?.profileTurnover && <PartnerTurnoverHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} partner={partner} cellStyle={cellStyle} />}
            {tableComponent?.profileCarbonWeight && <CarbonWeightHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} partner={partner} cellStyle={cellStyle} />}
            {tableComponent?.climateAction && <ClimatActionHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.scope12 && <RythmeReduction12Head filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.scope3 && <RythmeReduction3Head filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.objScope12 && <ObjectifRythmeReduction12Head filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.objScope3 && <ObjectifRythmeReduction3Head filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.targetSbt && <SbtTargetHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.targetSbtSimple && <SbtTargetSimpleHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.currentProgress && <CurrentProgressHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.scoreCdp && <CdpHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.tags && <TagsHead cellStyle={cellStyle} />}
            {tableComponent?.actionsCompleted && <ActionsCompletedHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {tableComponent?.profileRating && <RatingHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} partner={partner} cellStyle={cellStyle} />}
            {tableComponent?.status && <StatusHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />}
            {(hasWriteAccess && (tableComponent?.status || tableComponent?.profileRating)) && <ActionsHead cellStyle={cellStyle} />}
          </tr>
        </thead>
        <tbody className="bg-white tw-text-greyHeavy tw-overflow-hidden">
          {profiles && profiles.length <= 0 && (
            <div className="font-12 tw-py-5 tw-font-medium tw-border-solid tw-border-greySeven tw-border tw-flex tw-justify-center">
              {t('NO_RESULT')}
            </div>
          )}
          {!loading ? (
            profiles.map((profile, index) => (
              <TableRow
                key={profile.id + index}
                category={categoryFilter}
                partner={partner}
                partnerTags={partnerTags}
                getPartnerTags={getPartnerTags}
                profile={profile}
                profileStatusList={profileStatusList}
                tableComponent={tableComponent}
                hasWriteAccess={hasWriteAccess}
                edit={edit}
                isDashboard={isDashboard}
                cellStyle={cellStyle}
                getProfiles={getProfiles}
                categoryFilter={categoryFilter}
                sectorFilter={sectorFilter}
                sbtiFilter={sbtiFilter}
                sbtiFilterSimple={sbtiFilterSimple}
                statusFilter={statusFilter}
                currentProgressFilter={currentProgressFilter}
                actionPlanFilter={actionPlanFilter}
                tagsFilter={tagsFilter}
                sort={sort}
                filter={filter}
              />
            ))
          ) : (
            skeletonTrElements
          )}
          <tr id="more-items" className={`mx-auto tw-cursor-pointer tw-bg-greyEight hover:tw-bg-backgroundGrey w-100 mb-5 text-center tw-border tw-border-solid tw-border-greySeven ${(loading || (total === profiles.length) || total <= 10) ? 'd-none' : ''}`} aria-label="Page navigation" onClick={() => togglePage()}>
            <td colSpan="100%">
              <button type="button" className="btn fw-500 font-12 cloudy-grey-color text-decoration-underline">
                <img className="add-more-icon align-self-center me-2" src={downArrowGrey} alt="arrow down" />
                {t('SHOW_MORE')}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Table;
